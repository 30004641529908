import { ValueResult } from "@kwyjibo-developments/typescript-result";
import { IApiService } from "./ApiService";

export interface GameStore {
    name: string;
    url: string;
    accessDate: Date;
}

export interface GameDefinition {
    identifier: string;
    title: string;
    summary: string;
    releaseDate: Date;
    mediaUrl: string;
    description: string[];
    highlight: boolean;
    stores: GameStore[];    
}

export interface IGamesService {
    getGameDefinitions() : Promise<ValueResult<GameDefinition[]>>;
    getGameDefinition(gameId: string) : Promise<ValueResult<GameDefinition>>;
}

interface GamesApiResponseBody {
    games: GameResponseDefinition[];
}

interface GameApiResponseBody {
    game: GameResponseDefinition;
}

export interface GameResponseDefinition {
    identifier: string;
    title: string;
    summary: string;
    releaseDate: string;
    mediaUrl: string;
    description: string[];
    highlight: boolean;
    stores: GameStore[];    
}

export default class GamesService implements IGamesService {

    constructor(private apiService : IApiService) {}

    async getGameDefinitions(): Promise<ValueResult<GameDefinition[]>> {
        const result = await this.apiService.get<GamesApiResponseBody>("/games");
        return result.onSuccessValue(body => ValueResult.Success<GameDefinition[]>(
            body.games.map(b => {
                return {
                    identifier: b.identifier,
                    title: b.title,
                    summary: b.summary,
                    releaseDate: new Date(Date.parse(b.releaseDate)),
                    mediaUrl: b.mediaUrl,
                    description: b.description,
                    highlight: b.highlight,
                    stores: b.stores
                };
            })
            )) 
            ?? ValueResult.Failed(...(result.messages) ?? ["Unable to load game data."]);
    }

    async getGameDefinition(gameId: string) : Promise<ValueResult<GameDefinition>> {
        const result = await this.apiService.get<GameApiResponseBody>(`/games/${gameId}`);
        return result.onSuccessValue(body => {
            const b = body.game;
            const game = {
                identifier: b.identifier,
                title: b.title,
                summary: b.summary,
                releaseDate: new Date(Date.parse(b.releaseDate)),
                mediaUrl: b.mediaUrl,
                description: b.description,
                highlight: b.highlight,
                stores: b.stores
            };
            return ValueResult.Success<GameDefinition>(game);
            }) 
            ?? ValueResult.Failed(...(result.messages) ?? ["Unable to load game data."]);
    }
};