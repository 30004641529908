import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import diContainer from "../diContainer";
import { GameDefinition, IGamesService } from "../Services/GamesService";
import { StoreBadge } from "./AppStoreBadges";
import LoadingErrorList from "./LoadingErrorList";
import LoadingSymbol from "./LoadingSymbol";
import { JustifiedParagraph } from "./Paragraph";
import { StylisedFont } from "./Span";
import { Subtitle } from "./Title";


interface GameDisplayProps {
    game: GameDefinition;
}

const GamesGrid = styled.div`
    width: 100%;
    position: relative;

    margin: 0;
    padding: 0;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 0px 10px;

    @media (max-width: 576px) {
        grid-gap: 0;
    }
`;

const GamesGridGame = styled.div`
    width: 100%;

    margin: 0;
    padding: 20px;

    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: auto;
    grid-gap: 20px 10px;

    background-color: var(--game-bg);
    color: var(--game-text);

    @media (max-width: 576px) {
        padding: 0;
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
`;

const HighlightedGamesGridGame = styled(GamesGridGame)`
    background-color: var(--game-bg-highlight);
    color: var(--game-text-highlight);
    border-radius: 10px;
`;

const GamesGridItem = styled.div`
    padding: 0.5rem;
`;

const GameImage = styled.img`
    width: 100%;
`;

const GameTitle = styled(Subtitle)`
    margin: 0;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: var(--game-text);

    &:hover {
        color: var(--game-text-hover);
    }

    ${HighlightedGamesGridGame} & {
        color: var(--game-text-highlight);
    }

    ${HighlightedGamesGridGame} &:hover {
        color: var(--game-text-highlight-hover);
    }

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

const GameDisplay = ({game} : GameDisplayProps) => {
    return(<GamesGridGame>
        <GamesGridItem>
            <StyledLink to={`/library/${game.identifier}`}>
                <GameImage src={game.mediaUrl} alt={game.title}/>
            </StyledLink>
        </GamesGridItem>
        <GamesGridItem>
            <StyledLink to={`/library/${game.identifier}`}>
                <GameTitle>
                    <StylisedFont>{game.title}</StylisedFont>
                </GameTitle>
            </StyledLink>
            <JustifiedParagraph>{game.summary}</JustifiedParagraph>
            {game.stores.map(s => <StoreBadge key={`${s.name}-${game.identifier}`} store={s.name} url={s.url} theme="dark"/>)}
        </GamesGridItem>
    </GamesGridGame>);
};

const HighlightedGameDisplay = ({game} : GameDisplayProps) => {
    return(<HighlightedGamesGridGame>
        <GamesGridItem>
            <StyledLink to={`/library/${game.identifier}`}>
                <GameImage src={game.mediaUrl} alt={game.title}/>
            </StyledLink>
        </GamesGridItem>
        <GamesGridItem>
            <StyledLink to={`/library/${game.identifier}`}>
                <GameTitle>
                    <StylisedFont>{game.title}</StylisedFont>
                </GameTitle>
            </StyledLink>
            <JustifiedParagraph>{game.summary}</JustifiedParagraph>
            {game.stores.map(s => <StoreBadge key={`${s.name}-${game.identifier}`} store={s.name} url={s.url} theme="light"/>)}
        </GamesGridItem>
    </HighlightedGamesGridGame>);
};

const GamesDisplay = () => {

    const [showLoading, setShowLoading] = useState(true);
    const [loadingErrors, setLoadingErrors] = useState<string[]>([]);

    const [games, setGames] = useState<GameDefinition[]>([]);

    useEffect(() => {
        const runAsync = async function() {
            const service = diContainer.resolve<IGamesService>("IGamesService");
            const result = await service.getGameDefinitions();

            setShowLoading(false);

            if (result.isSuccess() && result.value != null) {
                const g = result.value;

                g.sort((a,b) => {

                    // handle cases where one item is highlighted and the other isn't
                    if (a.highlight && !b.highlight) {
                        return -1;
                    }
                    if (!a.highlight && b.highlight) {
                        return 1;
                    }

                    return a.releaseDate.valueOf() - b.releaseDate.valueOf();
                })

                setGames(g);
                return;
            }
            
            const errors = ['An error occurred while loading the data. Please try again soon.'];
            setLoadingErrors(errors);
        };
        runAsync();
    },[]);

    return (<>
        <LoadingSymbol visible={showLoading} />
        <LoadingErrorList visible={loadingErrors.length > 0} errors={loadingErrors} />
        <GamesGrid>
            {games.map(g => g.highlight 
                            ? <HighlightedGameDisplay key={g.identifier} game={g} />
                            : <GameDisplay key={g.identifier} game={g} />)}
        </GamesGrid>
    </>);
};

export default GamesDisplay;