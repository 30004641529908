import { useEffect, useState } from "react";
import styled from "styled-components";
import LoadingErrorList from "../Components/LoadingErrorList";
import LoadingSymbol from "../Components/LoadingSymbol";
import { JustifiedParagraph } from "../Components/Paragraph";
import { StylisedFont } from "../Components/Span";
import Title, { Subtitle } from "../Components/Title";
import diContainer from "../diContainer";
import { IConsultingService, Testamonial } from "../Services/ConsultingService";

const TestamonialList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

const TestamonialListItem = styled.li`
    margin: 0;
    padding: 0;
    padding-top: 1rem;
    border-top: 1px solid var(--border);

    &:first-child {
        padding-top: 0;
        border-top: none;
    };
`;

const TestamonialBody = styled.div`
    display: grid;
    grid-template-columns: 10rem auto; 
    column-gap: 10px; 
    margin: 0;
    padding: 1.5rem;

    @media (max-width: 576px) {
        grid-template-columns: auto; 
        column-gap: 0; 
    }
`;
const TestamonialBodyItem = styled.div`
    align-items: start;
`;

const TestamonialTitle = styled.h3`
    margin: 0;
`;
const TestamonialImage = styled.img`
    max-width: 10rem;
    max-height: 10rem;
    align-self: center;
`;

const ExternalLink = styled.a`
    text-decoration: none;

    color: var(--text-dark);

    &:hover {
        text-decoration: underline;
    }

    &:focus, &:visited {
        text-decoration: none;
    }
`;

const TestamonialContent = ({testamonial} : {testamonial: Testamonial}) => {
    return (<TestamonialBody>
        <TestamonialBodyItem>
            {testamonial.referenceUrl
                ? <ExternalLink href={testamonial.referenceUrl}>
                    <TestamonialImage src={testamonial.iconUrl} alt={testamonial.title} />
                </ExternalLink>
                : <TestamonialImage src={testamonial.iconUrl} alt={testamonial.title} />
            }
        </TestamonialBodyItem>
        <TestamonialBodyItem>
            {testamonial.referenceUrl
                ? <ExternalLink href={testamonial.referenceUrl}>
                    <TestamonialTitle>{testamonial.title}</TestamonialTitle>
                </ExternalLink>
                : <TestamonialTitle>{testamonial.title}</TestamonialTitle>
            }
            <JustifiedParagraph>by {testamonial.contactName}</JustifiedParagraph>
            {testamonial.content.map((c,i) => <JustifiedParagraph key={`${testamonial.contactName}-${testamonial.publishDate}-${i}`}>{c}</JustifiedParagraph>)}
        </TestamonialBodyItem>
    </TestamonialBody>);
};

const ConsultingPage = () => {

    const [showLoading, setShowLoading] = useState(true);
    const [loadingErrors, setLoadingErrors] = useState<string[]>([]);

    const [testamonials, setTestamonials] = useState<Testamonial[]>();

    useEffect(() => {
        const callback = async () => {
            const consultingService = diContainer.resolve<IConsultingService>("IConsultingService");
            const t = await consultingService.getTestamonials();
            
            setShowLoading(false);

            if (t.isSuccess() && t.value != null) {
                const items = t.value;
                items.sort((a,b) => b.publishDate.valueOf() - a.publishDate.valueOf());
                
                setTestamonials(items);
                return;
            }

            const errors = ['An error occurred while loading the data. Please try again soon.'];
            setLoadingErrors(errors);
        };

        callback();
    }, []);

    return (<>
        <Title><StylisedFont>Consulting</StylisedFont></Title>
        <JustifiedParagraph>
            We have widely varied experience in analysing complex problems due to extensive academic experience in a range of mathematical fields, including computational linear algebra, optimisation and data visualisation techniques. We have also developed several bespoke applications for a range of internal applications and confidential clients.
        </JustifiedParagraph>
        <JustifiedParagraph>
            We would love to help you on your path of analysing a problem and bring a solution that is appropriate for your business.
        </JustifiedParagraph>
        <Subtitle><StylisedFont>Completed projects</StylisedFont></Subtitle>
        <LoadingSymbol visible={showLoading} />
        <LoadingErrorList visible={loadingErrors.length > 0} errors={loadingErrors} />
        <TestamonialList>
            {testamonials?.map(t => <TestamonialListItem key={`${t.contactName}-${t.publishDate}`}>
                <TestamonialContent testamonial={t} />
            </TestamonialListItem>)}
        </TestamonialList>
    </>);
};

export default ConsultingPage;