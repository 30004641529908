import styled from "styled-components";

const Paragraph = styled.p``;

const JustifiedParagraph = styled(Paragraph)`
    text-align: justify;
`;

const CentredParagraph = styled(Paragraph)`
    text-align: center;
`;

export default Paragraph;

export {
    Paragraph,
    JustifiedParagraph,
    CentredParagraph
};