
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import styled from "styled-components";
import LoadingErrorList from "../Components/LoadingErrorList";
import LoadingSymbol from "../Components/LoadingSymbol";
import { JustifiedParagraph } from "../Components/Paragraph";
import { StylisedFont } from "../Components/Span";
import Title, { Subtitle } from "../Components/Title"
import diContainer from "../diContainer";
import ISocialMediaService, { SocialMediaLinkModel } from "../Services/SocialMediaService";

const ExternalLink = styled.a`
    color: var(--body-text);

    &:hover {
        color: var(--body-text-hover);
    }
`;

const SocialMediaDisplay = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;
const SocialMediaDisplayItem = styled.li`
    font-size: 1.1rem;
    padding: 0.5rem;
`;

const SocialMediaLinkDisplay = ({model} : {model: SocialMediaLinkModel}) => {
    return (<SocialMediaDisplayItem>
        <FontAwesomeIcon icon={model.icon} /> <ExternalLink href={model.url}>{model.service}</ExternalLink>
    </SocialMediaDisplayItem>);
};

const ContactPage = () => {

    const [showLoading, setShowLoading] = useState(true);
    const [loadingErrors, setLoadingErrors] = useState<string[]>([]);

    const [socialMedia, setSocialMedia] = useState<SocialMediaLinkModel[]>([]);

    useEffect(() => {
        const callback = async() => {
            const smService = diContainer.resolve<ISocialMediaService>("ISocialMediaService");
            const result = await smService.getLinks();
            
            setShowLoading(false);

            if (result.isSuccess() && result.value != null) {
                const sm = result.value;
                setSocialMedia(sm);
                return;
            }
            
            const errors = ['An error occurred while loading the data. Please try again soon.'];
            setLoadingErrors(errors);
        };

        callback();
    }, [])

    return (<>
        <Title><StylisedFont>Contact</StylisedFont></Title>
        <JustifiedParagraph>
            For business enquiries, email doc at <ExternalLink href="mailto:doc@kwyjibo.dev">doc@kwyjibo.dev</ExternalLink> or contact him on social media below.
        </JustifiedParagraph>
        <Subtitle><StylisedFont>Social Media</StylisedFont></Subtitle>
        <LoadingSymbol visible={showLoading} />
        <LoadingErrorList visible={loadingErrors.length > 0} errors={loadingErrors} />
        <SocialMediaDisplay>
            {socialMedia.map(s => <SocialMediaLinkDisplay key={s.service} model={s} />)}
        </SocialMediaDisplay>
    </>);
};

export default ContactPage;