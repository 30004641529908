import { ValueResult } from "@kwyjibo-developments/typescript-result";
import { IApiService } from "./ApiService";

export interface TeamPerson {
    id: string;
    name: string;
    imageUrl: string | null;
    content: string[];
}

export interface TeamPersonSummary {
    id: string;
    name: string;
    imageUrl: string | null;
}

export interface ITeamService {
    getTeamPeople() : Promise<ValueResult<TeamPersonSummary[]>>;
    getDetails(name: string) : Promise<ValueResult<TeamPerson>>;
}

interface TeamResponseBody {
    team: TeamPersonSummary[];
}

interface PersonResponseBody {
    person: TeamPerson;
}

export default class TeamService implements ITeamService {

    constructor(private apiService: IApiService) {}

    public async getTeamPeople() : Promise<ValueResult<TeamPersonSummary[]>> {
        const result = await this.apiService.get<TeamResponseBody>("/team");
        return result.onSuccessValue(body => ValueResult.Success<TeamPersonSummary[]>(body.team))
            ?? ValueResult.Failed(...(result.messages) ?? ["Unable to load team data."]);
    }
        
    public async getDetails(personId: string) : Promise<ValueResult<TeamPerson>> {
        const result = await this.apiService.get<PersonResponseBody>(`/team/${personId}`);
        return result.onSuccessValue(body => ValueResult.Success<TeamPerson>(body.person))
            ?? ValueResult.Failed(...(result.messages) ?? ["Unable to load person data."]);
    }
}