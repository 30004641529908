import { HTMLAttributes, useRef } from "react";
import styled from "styled-components";
import Logo from "./Logo";
import { Navbar, NavLogo, NavItem, NavLink, NavbarToggle, NavbarMenu } from "./Navbar";

const StyledHeader = styled.header``;

const Header = ({...props} : HTMLAttributes<HTMLHeadingElement>) => {

    const toggleRef = useRef<HTMLUListElement>(null);
    const toggleState = useRef(false);

    const toggleClick = () => {
        const newToggle = !toggleState.current;
        toggleState.current = newToggle;
        if (newToggle) {
            toggleRef.current?.classList.add("toggleOn");
        } else  {
            toggleRef.current?.classList.remove("toggleOn");
        }
    };

    return (
        <StyledHeader {...props}>
            <Navbar>
                <NavLogo>
                    <Logo />
                    <NavLink to="/">
                        kwyjibo development
                    </NavLink>
                </NavLogo>
                <NavbarMenu onClickCallback={toggleClick} />
                <NavbarToggle ref={toggleRef}>
                    <NavItem>
                        <NavLink to="/about">About</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/library">Library</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/consulting">Consulting</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/contact">Contact</NavLink>
                    </NavItem>
                </NavbarToggle>
            </Navbar>
        </StyledHeader>
    )
};

export default Header;