import styled from "styled-components";

const StyledLogo = styled.img`
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
`;

const Logo = () => <StyledLogo src="/logo.svg" alt="logo" />;

export default Logo;