import styled from "styled-components";

const Card = styled.div``;

const CardImg = styled.img`
    width: 100%;
    margin: 0;
    padding: 0;
    border: 1px solid var(--border-primary);
    border-radius: 1rem;
`;

const CardBody = styled.div`
margin: 0;
padding: 0;
`;

export {
    Card,
    CardImg,
    CardBody
};