import styled from "styled-components";

const FullWidthDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const CircularLoader = styled.div`
    border: 6px solid var(--loader-bg);
    border-top: 6px solid var(--loader-fg);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
`;

export interface LoadingSymbolProps {
    visible?: boolean;
}

const LoadingSymbol = ({visible} : LoadingSymbolProps) => {
    if (!visible) {
        return <></>;
    }
    
    return (<FullWidthDiv>
        <CircularLoader />
    </FullWidthDiv>);
};

export default LoadingSymbol;