import styled from "styled-components";
import ReactMarkdown from "react-markdown";

import {HTMLAttributes} from 'react';

const Span = styled.span``;

const Emph = styled.em``;

const Bold = styled.strong``;

const SmallCaps = styled(Span)`
    font-variant: small-caps;
`;

const StylisedFont = styled(Span)`
    font-family: 'No Virus';
`;

const Highlight = ({children, ...props} : HTMLAttributes<HTMLSpanElement>) => {
    return (
        <StylisedFont {...props}>
            {children as string}
        </StylisedFont>
    );
};

const Markdown = ({children, ...props} : HTMLAttributes<HTMLSpanElement>) => {
    return (
        <ReactMarkdown {...props}>
            {children as string}
        </ReactMarkdown>
    );
};

export default Span;

export {
    Span,
    Emph,
    Bold,
    Highlight,
    Markdown,
    StylisedFont,
    SmallCaps
};