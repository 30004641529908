import { ValueResult } from "@kwyjibo-developments/typescript-result";

enum ApiResponseStatus {
    Success,
    Failed,
    Excepted
}

interface ApiResponse<T> {
    status: ApiResponseStatus;
    data: T | null;
    messages: string[];
}

export interface IApiService {
    get<T>(path: string) : Promise<ValueResult<T>>;
}

export default class ApiService implements IApiService {
    
    constructor(private host: string) { }

    async get<T>(path: string): Promise<ValueResult<T>> {

        const uri = `${this.host}${path}`;

        try {
            const response = await fetch(uri);
            const body = (await response.json()) as ApiResponse<T>;

            if (body && body.data) {
                return ValueResult.Success(body.data);
            }
            return ValueResult.Failed<T>("API returned successfully with invalid data");
        } catch (e) {
            return ValueResult.Failed<T>(`Failed to load data at ${path}`, e.message);
        }
    }
}