import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import styled from "styled-components";
import diContainer from "../diContainer";
import { ISocialMediaService, SocialMediaLinkModel } from "../Services/SocialMediaService";

const StyledFooter = styled.footer`
    background-color: var(--navbar-bg);
    padding: 0.5rem 0;
`;

const SocialMediaIconGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
`;

const SocialMediaIconGridItem = styled.div`
    display: flex;
    flex-direction: row;
`;

const SocialMediaList = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
`;

const SocialMediaListItem = styled.li`
    margin: 0;
    margin-right: 0.5rem;
    padding: 0.5rem;

    &:last-child {
        margin-right: 0;
    }

    &:hover {
        background-color: var(--navbar-bg-hover);
    }
`;

const SocialMediaLink = styled.a`
    text-decoration: none;
    color: white;
`;

const SocialMediaLinkCollectionDisplay = ({links}: {links: SocialMediaLinkModel[]}) => {
    return <SocialMediaIconGrid>
        <SocialMediaIconGridItem />
        <SocialMediaIconGridItem>
            <SocialMediaList>
                {links.map(l => <SocialMediaListItem key={l.service} title={l.service}>
                                    <SocialMediaLink href={l.url}>
                                        <FontAwesomeIcon icon={l.icon} />
                                    </SocialMediaLink>
                                </SocialMediaListItem>)}
            </SocialMediaList>
        </SocialMediaIconGridItem>
        <SocialMediaIconGridItem />
    </SocialMediaIconGrid>;
};

const Footer = () => {

    const [socialMediaLinks, setSocialMediaLinks] = useState<SocialMediaLinkModel[]>([]);

    useEffect(() => {
        const runAsync = async () => {
            const smService = diContainer.resolve<ISocialMediaService>("ISocialMediaService");
            const result = await smService.getLinks();

            if (result.isSuccess()) {
                const links = result.value;
                setSocialMediaLinks(links ?? []);
            }
        };
        runAsync();
    }, []);

    return (<StyledFooter>
        <SocialMediaLinkCollectionDisplay links={socialMediaLinks} />
    </StyledFooter>);
}

export default Footer;