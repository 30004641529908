import styled from "styled-components";

export interface BadgeProps {
    url: string | null;
}

const ExternalLink = styled.a``;
const IconImage = styled.img``;

const Badge = ({iconUrl, linkUrl}: {iconUrl: string, linkUrl: string | null}) => {
    return (
        linkUrl !== null
        ? (<ExternalLink href={linkUrl}>
            <IconImage src={iconUrl} alt={linkUrl} />
        </ExternalLink>)
        : <></>
    );
};

const IosDarkBadge = ({url}: BadgeProps) => <Badge iconUrl="/media/ios-app-store-badge-dark.svg" linkUrl={url} />;
const IosLightBadge = ({url}: BadgeProps) => <Badge iconUrl="/media/ios-app-store-badge-light.svg" linkUrl={url} />;

const StoreBadge = ({store, url, theme} : {store: string, url: string, theme: string}) => {
    if (store.toLowerCase() === "apple") {
        if (theme.toLowerCase() === "dark") {
            return <IosDarkBadge url={url} />;
        } 
        else if (theme.toLowerCase() === "light") {
            return <IosLightBadge url={url} />;
        }
        throw new Error("Theme not supported: " + theme);
    }
    throw new Error("Store not supported: " + store);
}

export {
    IosDarkBadge,
    IosLightBadge,
    StoreBadge
};