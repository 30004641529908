import styled from "styled-components";

const StyledErrorList = styled.ul`
    list-style: none;
    text-align: left;
`;

const StyledErrorListItem = styled.li`
    color: var(--text-error);
`;

export interface LoadingErrorListProps {
    visible?: boolean;
    errors: string[];
}

const LoadingErrorList = ({visible, errors}: LoadingErrorListProps) => {
    if (!visible) {
        return <></>;
    }

    return (<StyledErrorList>
        {errors.map((e,i) => <StyledErrorListItem key={`err${i}`}>{e}</StyledErrorListItem>)}
    </StyledErrorList>);
};

export default LoadingErrorList;