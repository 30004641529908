import { ValueResult } from "@kwyjibo-developments/typescript-result";
import { IApiService } from "./ApiService";

export interface Testamonial {
    title: string;
    referenceUrl: string | null;
    iconUrl: string;
    contactName: string;
    content: string[];
    publishDate: Date;
}

export interface IConsultingService {
    getTestamonials(): Promise<ValueResult<Testamonial[]>>;
}

interface ConsultingApiResponseBody {
    items: TestamonialResponse[];
}

interface TestamonialResponse {
    title: string;
    referenceUrl: string | null;
    iconUrl: string;
    contactName: string;
    content: string[];
    publishDate: string;
}

export default class ConsultingService implements IConsultingService {
    
    constructor(private apiService : IApiService) {}
    
    async getTestamonials(): Promise<ValueResult<Testamonial[]>> {
        const result = await this.apiService.get<ConsultingApiResponseBody>("/consulting");
        return result.onSuccessValue(body => ValueResult.Success<Testamonial[]>(
            body.items.map(b => {
                return {
                    title: b.title,
                    referenceUrl: b.referenceUrl,
                    iconUrl: b.iconUrl,
                    contactName: b.contactName,
                    content: b.content,
                    publishDate: new Date(Date.parse(b.publishDate))
                };
            }))) 
            ?? ValueResult.Failed(...(result.messages) ?? ["Unable to load consulting data."]);
    }

} 