import Container, { Singleton }  from '@kwyjibo-developments/typescript-dependency-injection';
import config from './config';
import ApiService from './Services/ApiService';
import CachedApiService from './Services/CachedApiService';
import ConsultingService from './Services/ConsultingService';
import GamesService from './Services/GamesService';
import SocialMediaService from './Services/SocialMediaService';
import TeamService from './Services/TeamService';

const diContainer = new Container()
    .registerService(Singleton.fromInstance("Config:ApiHost", config.apiHost))
    .registerService(Singleton.ofType("IBaseApiService", ApiService).withDependencies(["Config:ApiHost"]))
    .registerService(Singleton.ofType("IApiService", CachedApiService).withDependencies(["IBaseApiService"]))
    .registerService(Singleton.ofType("IConsultingService", ConsultingService).withDependencies(["IApiService"]))
    .registerService(Singleton.ofType("IGamesService", GamesService).withDependencies(["IApiService"]))
    .registerService(Singleton.ofType("ISocialMediaService", SocialMediaService).withDependencies(["IApiService"]))
    .registerService(Singleton.ofType("ITeamService", TeamService).withDependencies(["IApiService"]))
    .build();

export default diContainer;