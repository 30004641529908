import { useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import { StoreBadge } from "../Components/AppStoreBadges";
import LoadingErrorList from "../Components/LoadingErrorList";
import LoadingSymbol from "../Components/LoadingSymbol";
import Paragraph, { JustifiedParagraph } from "../Components/Paragraph";
import { StylisedFont } from "../Components/Span";
import Title from "../Components/Title";
import diContainer from "../diContainer";
import { GameDefinition, IGamesService } from "../Services/GamesService";

const GameFlexContainer = styled.div`
    width: 100%;
    position: relative;

    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {
        flex-direction: column-reverse;
    }
`;

const GameFlexItem = styled.div`
    flex-grow: 1;
    padding: 10px;
`;

const GameImage = styled.img`
    width: 100%;
`;

const GameDisplay = ({game}: {game: GameDefinition}) => {
    return (<>
        <Title>
            <StylisedFont>{game.title}</StylisedFont>
        </Title>
        <JustifiedParagraph>
            {game.summary}
        </JustifiedParagraph>
        <Paragraph>
        {game.stores.map(s => <StoreBadge key={`${s.name}-${game.identifier}`} store={s.name} url={s.url} theme="dark"/>)}
        </Paragraph>
        <GameFlexContainer>
            <GameFlexItem>
                {game.description.map((p,i) => <JustifiedParagraph key={`${game.identifier}-${i}`}>{p}</JustifiedParagraph>)} 
            </GameFlexItem>
            <GameFlexItem>
                <GameImage src={game.mediaUrl} alt={game.title} />
            </GameFlexItem>
        </GameFlexContainer>
    </>);
}

const GamesPage = () => {
    const { gameId } = useParams<{gameId: string}>();

    const [showLoading, setShowLoading] = useState(true);
    const [loadingErrors, setLoadingErrors] = useState<string[]>([]);

    const [game, setGame] = useState<GameDefinition|null>(null);

    useEffect(() => {
        const getAsync = async() => {
            const service = diContainer.resolve<IGamesService>("IGamesService");
            const result = await service.getGameDefinition(gameId);
            setShowLoading(false);

            if (result.isSuccess() && result.value != null) {
                setGame(result.value);
                return;
            }
            
            const errors = ['An error occurred while loading the data. Please try again soon.'];
            setLoadingErrors(errors);
        };

        getAsync();
    }, [gameId]);

    return (<>
        <LoadingSymbol visible={showLoading} />
        <LoadingErrorList visible={loadingErrors.length > 0} errors={loadingErrors} />
        {game != null && <GameDisplay game={game} />}
    </>);
};

export default GamesPage;