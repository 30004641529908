import styled from "styled-components";
import GamesDisplay from "../Components/GamesDisplay";
import { StylisedFont } from "../Components/Span";
import Title from "../Components/Title";

const FeatureList = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;
`;

const FeatureListItem = styled.li`
    font-size: 1.3rem;
    padding-top: 0.5rem;

    &:first-child {
        padding-top: 0;
    }
`;

const HomePage = () => {
    return (<>
        <FeatureList>
            <FeatureListItem>
                <StylisedFont>
                    Independent game developer and publisher
                </StylisedFont>
            </FeatureListItem>
            <FeatureListItem>
                <StylisedFont>
                    Creating engaging games for mobile and other platforms
                </StylisedFont>
            </FeatureListItem>
            <FeatureListItem>
                <StylisedFont>
                    Experienced data analyst and data visualisation expert
                </StylisedFont>
            </FeatureListItem>
        </FeatureList>

        <Title><StylisedFont>Game Library</StylisedFont></Title>
        <GamesDisplay />
    </>);
};

export default HomePage;