import { Route, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import StandardLayout from './Components/StandardLayout';
import AboutPage from './Pages/AboutPage';
import ConsultingPage from './Pages/ConsultingPage';
import ContactPage from './Pages/ContactPage';
import GamesPage from './Pages/GamesPage';
import HomePage from './Pages/HomePage';
import LibraryPage from './Pages/LibraryPage';
import NotFoundPage from './Pages/NotFound';
import './Styles/styles.scss';

function App() {
  return (
      <Router>    
        <StandardLayout>
          <Switch>
            <Route path="/" exact component={HomePage}/>
            <Route path="/about" component={AboutPage} />
            <Route path="/library" exact component={LibraryPage} />
            <Route path="/library/:gameId" component={GamesPage} />
            <Route path="/consulting" component={ConsultingPage} />
            <Route path="/contact" component={ContactPage} />
            <Route component={NotFoundPage} />
          </Switch>
        </StandardLayout>
      </Router>
  );
}

export default App;
