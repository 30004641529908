import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledNavbar = styled.nav`
    position: fixed;
    background-color: var(--navbar-bg);
    top: 0;
    width: 100vw;
    height: 4rem;
    color: var(--navbar-text);
    overflow-y: visible;
    z-index: 100;
`;

const NavbarNav = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const NavItem = styled.li`
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    height: 100%;

    min-width: 100px;

    @media (min-width: 576px) {
        &:last-child {
            margin-right: auto;
        }
    }

    @media (max-width: 576px) {
        height: 3rem;
        width: 100%;
    }
`;

const NavLogo = styled(NavItem)`
    font-family: 'No Virus', Impact;
    margin-left: 2rem;
    margin-right: 2rem;
    padding: 0;
    
    font-size: 1.5rem;

    justify-content: center;
    align-items: center;

    @media (max-width: 576px) {
        margin-left: 0.2rem;
    }
`;

const NavLink = styled(Link)`
    display: flex;
    color: var(--navbar-text);
    text-decoration: none;
    transition: var(--transition-speed);

    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    &:hover {
        background: var(--navbar-bg-hover);
        font-weight: bold;
    }

    ${NavLogo} &:hover {
        font-weight: normal;
    }
`;

const StyledNavbarMenu = styled.button`
    font-size: 1.3rem;

    border: none;
    background: transparent;
    color: inherit;

    @media (min-width: 576px) {
        display: none;
    }
    @media (max-width: 576px) {
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background: var(--navbar-bg-hover);
            font-weight: bold;
        }
    }
`;

const NavbarMenu = ({onClickCallback} : {onClickCallback: () => void}) => (
    <StyledNavbarMenu 
        onClick={onClickCallback}>
        <FontAwesomeIcon icon={faBars} />
    </StyledNavbarMenu>
);

const NavbarToggle = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    margin-right: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 576px) {
        position: absolute;
        top: 4rem;
        width: 100%;

        height: max-content;
        max-height: 0;
        
        overflow: hidden;
        display: flex;
        transition: var(--transition-speed);
        flex-direction: column;
        background-color: var(--navbar-bg);

        &.toggleOn {
            max-height: 100vh;
        }
    }
`;

const Navbar = ({children, ...props} : HTMLAttributes<HTMLDivElement>) => {
    return(
        <StyledNavbar {...props}>
            <NavbarNav>
                {children}
            </NavbarNav>
        </StyledNavbar>
    );
}

export {
    Navbar,
    NavLogo,
    NavbarMenu,
    NavbarToggle,
    NavItem,
    NavLink
} ;

