import styled from "styled-components";

const Title = styled.h1``;

const Subtitle = styled.h2`
    font-variant: small-caps;
`;

export default Title;

export {
    Title,
    Subtitle
};