import Title from "../Components/Title";
import { Subtitle } from "../Components/Title";
import { Markdown, SmallCaps, StylisedFont } from "../Components/Span";
import  { useEffect, useMemo, useState } from "react";
import { ITeamService, TeamPerson, TeamPersonSummary } from "../Services/TeamService";
import styled from "styled-components";

import { Card, CardImg, CardBody } from './Card';
import diContainer from "../diContainer";
import LoadingErrorList from "./LoadingErrorList";
import LoadingSymbol from "./LoadingSymbol";

const TeamDisplayGrid = styled.div`
    display: grid;
    width: 100%;
    gap: 10px;

    grid-template-columns: repeat(2, 1fr);

    @media (min-width: 994px) {
        grid-template-columns: repeat(5, 1fr);
    }

    @media (min-width: 576px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;


const TeamPersonCard = ({person, clickedCallback}: {person: TeamPersonSummary, clickedCallback: (name: string) => void}) => {
    return (<Card onClick={() => clickedCallback(person.id)}>
        <CardImg src={person.imageUrl ?? ""}/>
        <CardBody>
            <SmallCaps>{person.name}</SmallCaps>
        </CardBody>
    </Card>);
};

const TeamDisplayContainer = ({team, clickedCallback}: {team: TeamPersonSummary[], clickedCallback: (name: string) => void}) => {
    return <TeamDisplayGrid>
            {team.map(t => <TeamPersonCard key={t.id} person={t} clickedCallback={clickedCallback}/>)}
    </TeamDisplayGrid>;
};

const PersonDisplay = ({person} : {person: TeamPerson}) => {

    return <>
        <Subtitle>{person.name}</Subtitle>
        {person.content.map((d,i) => <Markdown key={`${person.name}-${i}`} >{d}</Markdown>)}
    </>;
}

const TeamDisplay = () => {

    const teamService = useMemo(() => diContainer.resolve<ITeamService>("ITeamService"), []);

    const [showTeamLoading, setShowTeamLoading] = useState(true);
    const [teamLoadingErrors, setTeamLoadingErrors] = useState<string[]>([]);

    const [showPersonLoading, setShowPersonLoading] = useState(false);
    const [personLoadingErrors, setPersonLoadingErrors] = useState<string[]>([]);

    const [allTeam, setAllTeam] = useState<TeamPersonSummary[]>([]);
    const [selectedPerson, setSelectedPerson] = useState<string|null>(null);
    const [selectedPersonDetails, setSelectedPersonDetails] = useState<TeamPerson | null>(null);

    useEffect(() => {

        const runAsync = async function() {
            const result = await teamService.getTeamPeople();
            setShowTeamLoading(false);
            if (result.isSuccess() && result.value != null) {
                const team = result.value;
                setAllTeam(team);
                if (team.length > 0) {
                    setSelectedPerson(team[0].id);
                }
                return;
            }

            const errors = ['An error occurred while loading the team data. Please try again soon.'];
            setTeamLoadingErrors(errors);
        };
        runAsync();
    }, [teamService]);

    useEffect(() => {
        const runAsync = async function() {
            if (selectedPerson !== null) {
                setShowPersonLoading(true);
                const result = await teamService.getDetails(selectedPerson);
                setShowPersonLoading(false);

                if (result.isSuccess() && result.value != null) {
                    const person = result.value;
                    setSelectedPersonDetails(person);
                    return;
                }

                const errors = [`An error occurred while loading the data for ${selectedPerson}. Please try again soon.`];
                setPersonLoadingErrors(errors);
            }
        }

        runAsync();

    }, [selectedPerson, teamService]);

    return (<>
        <Title><StylisedFont>The Team</StylisedFont></Title>
        <LoadingSymbol visible={showTeamLoading} />
        <LoadingErrorList visible={teamLoadingErrors.length > 0} errors={teamLoadingErrors} />
        <TeamDisplayContainer team={allTeam} clickedCallback={setSelectedPerson} />
        {selectedPersonDetails !== null && <>
            <LoadingSymbol visible={showPersonLoading} />
            <LoadingErrorList visible={personLoadingErrors.length > 0} errors={personLoadingErrors} />
            <PersonDisplay person={selectedPersonDetails} />
        </>}
    </>
    );
};

export default TeamDisplay;