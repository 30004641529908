import { ValueResult } from "@kwyjibo-developments/typescript-result";
import { IApiService } from "./ApiService";

export default class CachedApiService implements IApiService {
    
    private cache: { [key: string]: ValueResult<any> };

    constructor(private apiHost: IApiService) {
        this.cache = {};
    }
    
    async get<T>(path: string): Promise<ValueResult<T>> {
        
        if (this.cache.hasOwnProperty(path)) {
            console.log(`Returning from cache: ${path}`);
            return this.cache[path];
        }

        console.log(`Fetching: ${path}`);
        const result = await this.apiHost.get<T>(path);
        this.cache[path] = result;
        return result;
    }
    
}