import { JustifiedParagraph } from "../Components/Paragraph";
import { Highlight, StylisedFont } from "../Components/Span";
import TeamDisplay from "../Components/TeamDisplay";
import Title from "../Components/Title";

const AboutPage = () => {
    return (<>
        <Title><StylisedFont>The Studio</StylisedFont></Title>
        <JustifiedParagraph>
            <Highlight>kwyjibo development</Highlight> is an independent Brisbane based software development studio founded in 2021. The studio primarily focuses on developing mobile games that provide a unique experience. The studio may also consider other forms of applications and platforms over time. The studio is also available for bespoke applications and analytical work upon client request.
        </JustifiedParagraph>
        <JustifiedParagraph>
            We believe that the true cost of the game should be known upfront, so no games published by the studio will contain any advertisements or microtransactions, characteristics extremely common in many mobile applications. After the initial purchase, the player will face no pay walls, no time gates and no advertising to experience all aspects of the game. All expansions will be distributed free to players or available for purchase at a fixed price.
        </JustifiedParagraph>
        <TeamDisplay />
    </>)
};

export default AboutPage;