import styled from "styled-components";
import Header from "./Header";

import GlobalFonts from '../fonts/fonts';
import Footer from "./Footer";
import { HTMLAttributes } from "react";

const GridLayout = styled.div`
    position: relative;
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;

    display: grid;
    gap: 0 0;

    grid-template-rows: max-content auto max-content;

    @media (min-width: 576px) {
        grid-template-columns: minmax(0, 10%) minmax(576px,80%) minmax(0, 10%);
        grid-template-areas:
            "header header header"
            ". content ."
            "footer footer footer";
    }
    @media (max-width: 576px) {
        grid-template-columns: 100%;
        grid-template-areas:
            "header"
            "content"
            "footer";
    }
`;

const ContentWrapper = styled.div<{gridArea: string}>`
    grid-area: ${props => props.gridArea};
`;

const ContentAreaWrapper = styled(ContentWrapper)`

    margin-top: 4rem;
    padding-bottom: 1rem;

    @media (max-width: 576px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
`;


const StandardLayout = ({children, ...props}: HTMLAttributes<HTMLDivElement>) => {
    return (<GridLayout>
        <GlobalFonts />
        <ContentWrapper gridArea="header">
            <Header/>
        </ContentWrapper>
        
        <ContentAreaWrapper gridArea="content" {...props}>
            {children}
        </ContentAreaWrapper>
        
        <ContentWrapper gridArea="footer">
            <Footer />
        </ContentWrapper>
    </GridLayout>);
};

export default StandardLayout;