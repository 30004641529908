import { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import styled from "styled-components";
import { CentredParagraph } from "../Components/Paragraph";

const CentredImage = styled.img`
    width: 80%;
    max-width: 500px;
    margin: 0 auto;
    display: block;
`;

const NotFoundPage = () => {

    const [redirectTime, setRedirectTime] = useState(10);

    useEffect(() => {
        const t = setTimeout(() => {
            setRedirectTime(redirectTime - 1);
        }, 1000);

        return () => {
            // clean up the timer incase it is still running
            clearTimeout(t);
        };
    }, [redirectTime]);

    if (redirectTime <= 0) {
        return <Redirect to="/" />;
    }

    return (<>
        <CentredImage src="/media/not-found.png" alt="Page not found" />
        <CentredParagraph>
            Oops. It seems like you found a page that doesn't exist.
        </CentredParagraph>
        <CentredParagraph>
            You will be redirected to the <Link to="/">home page</Link> in {redirectTime} seconds.
        </CentredParagraph>
    </>);
}

export default NotFoundPage;