import { faTwitch, faTwitter, faYoutube, IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { ValueResult } from "@kwyjibo-developments/typescript-result";
import { IApiService } from "./ApiService";

export interface SocialMediaLinkModel {
    service: string;
    url: string;
    icon: IconDefinition;
};

export interface ISocialMediaService {
    getLinks(): Promise<ValueResult<SocialMediaLinkModel[]>>;
}

interface SocialMediaApiResponseBody {
    links: SocialMediaApiResponseLinkModel[];
}

interface SocialMediaApiResponseLinkModel {
    service: string;
    url: string;
    icon: string;
};

function iconFactory(iconName: string) : IconDefinition {
    switch (iconName.toLowerCase()) {
        case "twitter":
            return faTwitter;
        case "twitch":
            return faTwitch;
        case "youtube":
            return faYoutube;
        default:
            return faLink;
    }
}

export default class SocialMediaService implements ISocialMediaService {

    constructor(private apiService: IApiService) {}

    async getLinks(): Promise<ValueResult<SocialMediaLinkModel[]>> {
        const result = await this.apiService.get<SocialMediaApiResponseBody>("/socials");

        return result.onSuccessValue(body => ValueResult.Success<SocialMediaLinkModel[]>(
            body.links.map(l => {return {
                service: l.service,
                url: l.url,
                icon: iconFactory(l.icon)
            }}))) 
            ?? ValueResult.Failed(...(result.messages) ?? ["Unable to load social media data."]);
    }

}