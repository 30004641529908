import GamesDisplay from "../Components/GamesDisplay";
import { StylisedFont } from "../Components/Span";
import Title from "../Components/Title";

const LibraryPage = () => {
    return (<>
        <Title><StylisedFont>Library</StylisedFont></Title>
        <GamesDisplay />
    </>);
};

export default LibraryPage;